import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import MissingContent from '../errorMessages/MissingContent';

const CollectionsGrid = ({
  data,
  applicationName,
  marginBottom,
  marginTop,
}: any) => {
  const navigate = useNavigate();

  return (
    <>
      {data === null || data.length === 0 ? (
        <MissingContent sectionName="Collections Section" />
      ) : (
        <Box
          sx={{
            mt: marginTop,
            mb: marginBottom,
            ml: { xs: '5%', lg: '5rem' },
            mr: { xs: '5%', lg: '5rem' },
          }}
        >
          <Typography
            variant="h4"
            mb={4}
            fontWeight="400"
            fontSize={'56px'}
          >{`${applicationName
            .split(' ')
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')} Collections`}</Typography>

          <Grid container columnSpacing={5} rowSpacing={8}>
            {data
              ?.sort((a: any, b: any) =>
                a.collection_name.localeCompare(b.collection_name)
              )
              .map((elem: any, index: number) => (
                <Grid key={index} item xs={6} sm={3} md={2}>
                  <Box
                    sx={{
                      cursor: 'pointer',
                      height: {
                        xs: '150px',
                        lg: '276px',
                      },
                      width: { xs: '100%', lg: '100%' },
                      '&:hover img': {
                        transform: 'scale(1.03)',
                      },
                      overflow: 'hidden',
                    }}
                    onClick={() => {
                      navigate(
                        `../item_group/${elem?.item_group_code}/application/${elem?.application_name}/collection/${elem?.collection_name}`
                      );
                    }}
                  >
                    <img
                      src={elem?.url}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.5s ease',
                      }}
                      alt="categories"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '100%',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      mt={1}
                      fontWeight={'600'}
                      textTransform={'capitalize'}
                    >
                      {' '}
                      {elem?.collection_name}
                    </Typography>
                  </Box>
                </Grid>
              ))
              .filter(
                (value: any, index: any, self: string | any[]) =>
                  self.indexOf(value) === index
              )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CollectionsGrid;
