import { useEffect, useState, useRef } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import { apiSearch } from '../../../api/apiSearch';
import { useQuery } from 'react-query';
import ModalSection from './components/ModalSection';
import { Close } from '@mui/icons-material';

function SearchModal({ modalIsOpen, handleCloseModal }: any) {
    const [searchTerm, setSearchTerm] = useState('');
    const [showCategory, setShowCategory] = useState(false);
    const [showApplications, setShowApplications] = useState(false);
    const [showBrands, setShowBrands] = useState(false);
    const [showCollections, setShowCollections] = useState(false);
    const [showDesigns, setShowDesigns] = useState(false);
    const [showColours, setShowColours] = useState(false);
    const [showDesignStyles, setShowDesignStyles] = useState(false);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const {
        data: searchResultsData,
        isLoading,
        isError,
        isSuccess,
    } = useQuery(['search-term', searchTerm], () => apiSearch(searchTerm), {
        enabled: !!searchTerm,
    });

    const isEmptyObjectArrays = (
        obj: { [s: string]: unknown } | ArrayLike<unknown>
    ) =>
        Object.values(obj).every(
            (arr) => Array.isArray(arr) && arr.length === 0
        );

    useEffect(() => {
        if (modalIsOpen) {
            setShowCategory(false);
            setShowApplications(false);
            setShowBrands(false);
            setShowCollections(false);
            setShowDesigns(false);
            setShowDesignStyles(false);
            setSearchTerm('');
            setTimeout(() => {
                searchInputRef.current?.focus();
            }, 0);
        }
    }, [modalIsOpen]);

    return (
        <>
            <Dialog onClose={handleCloseModal} open={modalIsOpen} fullWidth>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    }}
                >
                    <DialogTitle sx={{ flex: 1 }}>
                        <Typography
                            variant='caption'
                            sx={{
                                color: '#000',
                                fontSize: { xs: '16px', lg: '24px' },
                                fontWeight: '700',
                                overflow: 'hidden',
                            }}
                        >
                            Already know what you are looking for?
                        </Typography>
                    </DialogTitle>
                    <Button
                        sx={{
                            px: '0',
                        }}
                        onClick={handleCloseModal}
                    >
                        <Close
                            style={{
                                width: '20px',
                                height: '20px',
                            }}
                        />
                    </Button>
                </Box>
                <DialogContent sx={{ width: 'auto', pb: '50px' }}>
                    <DialogContentText sx={{ mb: '0.1rem' }}>
                        <Typography variant='caption'>
                            Type the name of the design or collection here:
                        </Typography>
                    </DialogContentText>

                    <TextField
                        type='text'
                        id='search'
                        value={searchTerm}
                        inputRef={searchInputRef}
                        autoFocus
                        inputProps={{
                            sx: {
                                fontFamily: 'Inter',
                            },
                        }}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        size='small'
                        fullWidth
                    />
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}

                    {isError && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <Typography
                                variant='caption'
                                sx={{
                                    color: '#000',
                                    fontSize: '22px',
                                    fontWeight: '600',
                                }}
                            >
                                An error occured, please try again later
                            </Typography>
                        </Box>
                    )}
                    {isSuccess && searchTerm.length > 0 && (
                        <>
                            {isEmptyObjectArrays(searchResultsData?.results) ===
                            false ? (
                                <>
                                    <ModalSection
                                        title={'Categories'}
                                        resultsData={searchResultsData}
                                        sectionName={'Category'}
                                        keyName={'group_desc'}
                                        showSection={showCategory}
                                        setShowSection={setShowCategory}
                                        itemCount={
                                            searchResultsData?.results?.Category
                                                ?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                    <ModalSection
                                        title={'Applications'}
                                        resultsData={searchResultsData}
                                        sectionName={'Application'}
                                        keyName={'application'}
                                        showSection={showApplications}
                                        setShowSection={setShowApplications}
                                        itemCount={
                                            searchResultsData?.results
                                                ?.Application?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                    <ModalSection
                                        title={'Brands'}
                                        resultsData={searchResultsData}
                                        sectionName={'Brand'}
                                        keyName={'brand'}
                                        showSection={showBrands}
                                        setShowSection={setShowBrands}
                                        itemCount={
                                            searchResultsData?.results?.Brand
                                                ?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />

                                    <ModalSection
                                        title={'Collections'}
                                        resultsData={searchResultsData}
                                        sectionName={'Collection'}
                                        keyName={'range'}
                                        showSection={showCollections}
                                        setShowSection={setShowCollections}
                                        itemCount={
                                            searchResultsData?.results
                                                ?.Collection?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                    <ModalSection
                                        title={'Designs'}
                                        resultsData={searchResultsData}
                                        sectionName={'Design'}
                                        keyName={'design'}
                                        showSection={showDesigns}
                                        setShowSection={setShowDesigns}
                                        itemCount={
                                            searchResultsData?.results?.Design
                                                ?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                    <ModalSection
                                        title={'Colours'}
                                        resultsData={searchResultsData}
                                        sectionName={'Colour'}
                                        keyName={'colour'}
                                        showSection={showColours}
                                        setShowSection={setShowColours}
                                        itemCount={
                                            searchResultsData?.results?.Colour
                                                ?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                    <ModalSection
                                        title={'Design Style'}
                                        resultsData={searchResultsData}
                                        sectionName={'design_style'}
                                        keyName={'design_style'}
                                        showSection={showDesignStyles}
                                        setShowSection={setShowDesignStyles}
                                        itemCount={
                                            searchResultsData?.results
                                                ?.design_style?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <Typography
                                        variant='caption'
                                        sx={{
                                            color: '#000',
                                            fontSize: '22px',
                                            fontWeight: '600',
                                        }}
                                    >
                                        No items found
                                    </Typography>
                                </Box>
                            )}
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default SearchModal;
