import { useEffect } from 'react';
import { apiGetCategoryMainPageData } from '../../../api/apiCategories';
import FeaturedGallery from '../gallery/FeaturedGallery';
import ApplicationsGrid from '../grid/ApplicationsGrid';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import LoadingScreen from '../loading/LoadingScreen';
import TextHeader from '../../textHeader/TextHeader';
import NewArrivalsGrid from '../../../components/shared/grid/NewArrivalsGrid';
import CarouselBanner from '../banner/CarouselBanner';
import MissingPage from '../errorMessages/MissingPage';
import HomewareCategoryGrid from '../grid/HomewareCategoryGrid';
import FilterComponent from '../filter/FilterComponent';
import { captureAnalytics } from '../../../utilities/Analytics';

const CategoryTemplate = () => {
  const { item_group } = useParams();

  const itemGroupData = {
    category: { item_group: item_group },
  };
  const location = useLocation();

  const {
    isSuccess,
    isError,
    isLoading,
    data: categoryData,
  } = useQuery<any, Error>(
    [`main-category-${item_group}`],
    () => apiGetCategoryMainPageData(itemGroupData),
    { staleTime: 0, cacheTime: 0 }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isSuccess && categoryData) {
      const title = categoryData.page_title;
      captureAnalytics('pageview', location.pathname, title);
    }
  }, [location.pathname, item_group, isSuccess, categoryData]);

  return (
    <>
      {isError && <MissingPage />}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {isSuccess && (
            <>
              {' '}
              <CarouselBanner
                images={categoryData?.section_hero}
                video={categoryData?.section_hero_video?.video_url}
              />
              <TextHeader
                heading={categoryData?.page_title}
                content={categoryData?.description}
                isCentered={false}
                width="70%"
                marginTop={10}
                marginBottom={5}
              />
              {item_group === '000' ? (
                <>
                  {' '}
                  <HomewareCategoryGrid
                    data={categoryData?.section_homeware_landing_categories}
                    spacingValue={6}
                    marginTop={5}
                    marginBottom={5}
                  />
                </>
              ) : (
                <ApplicationsGrid
                  data={categoryData?.section_application_cards}
                  hasTitles={false}
                  hasButtons={false}
                  hasBackgroundText={true}
                  spacingValue={4}
                  marginTop={15}
                  marginBottom={15}
                />
              )}
              <FeaturedGallery
                images={categoryData?.section_featured_collection}
                hasVideo={false}
                invert={false}
                marginTop={5}
                marginBottom={5}
                title={categoryData?.featured_collection_title?.collection_name}
                description={categoryData?.featured_collection_description}
                itemGroup={
                  categoryData?.featured_collection_title?.item_group_code
                }
                application={
                  categoryData?.featured_collection_title?.application_name
                }
                collection={
                  categoryData?.featured_collection_title?.collection_name
                }
              />
              <NewArrivalsGrid
                header="New arrivals"
                data={categoryData?.section_new_arrivals}
                hasTitles={true}
                hasButtons={true}
                hasBackgroundText={false}
                spacingValue={4}
                marginTop={'5rem'}
                marginBottom={'5rem'}
              />
              <FilterComponent
                itemGroup={item_group}
                title={categoryData?.page_title}
                initialApplications={[]}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CategoryTemplate;
