import { useEffect } from 'react';
import CategoriesGrid from '../../components/shared/grid/CategoriesGrid';
import NewArrivalsGrid from '../../components/shared/grid/NewArrivalsGrid';
import Internationals from '../../components/shared/internationals/Internationals';
import MailingListSignup from '../../components/shared/mailing/Mailing';
import LoadingScreen from '../../components/shared/loading/LoadingScreen';
import { useQuery } from 'react-query';
import { apiGetHomePageData } from '../../api/apiHome';
import MissingPage from '../../components/shared/errorMessages/MissingPage';
import TextHeader from '../../components/textHeader/TextHeader';
import FeaturedGallery from '../../components/shared/gallery/FeaturedGallery';
import CarouselBanner from '../../components/shared/banner/CarouselBanner';
import { captureAnalytics } from '../../utilities/Analytics';

function HomePage() {
    const item_group = '999';
    const itemGroupData = {
        category: { item_group: item_group },
    };

    const {
        isError,
        isLoading,
        isSuccess,
        data: homePageData,
    } = useQuery<any, Error>([`home-page-${item_group}`], () =>
        apiGetHomePageData(itemGroupData)
    );


    useEffect(() => {
        captureAnalytics('pageview', '/', 'Home')
    }, []);

    return (
        <>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    {isError && <MissingPage />}
                    {isSuccess && (
                        <>
                            <CarouselBanner
                                images={homePageData.section_hero}
                                video={homePageData?.section_hero_video?.video_url}
                            />

                            <TextHeader
                                heading={'Welcome to Hertex'}
                                content={
                                    'Discover a world of inspiration through our premium fabric and wall coverings. At Hertex, we pride ourselves on offering expertly sourced collections that cater to diverse needs, from corporate and hospital settings to residential and contract applications. Whether you’re looking for upholstery, curtaining, or versatile multi-purpose fabrics, our contemporary designs ensure both quality and style.'
                                }
                                isCentered={false}
                                width='70%'
                                marginTop={10}
                                marginBottom={5}
                            />
                            <CategoriesGrid
                                data={homePageData.section_item_group_images}
                                hasTitles={false}
                                hasButtons={false}
                                hasBackgroundText={true}
                                spacingValue={6}
                                hasCollectionLinks={false}
                                marginTop={5}
                                marginBottom={5}
                            />

                            <NewArrivalsGrid
                                header='New arrivals'
                                data={homePageData?.section_new_arrivals}
                                hasTitles={true}
                                hasButtons={true}
                                hasBackgroundText={false}
                                spacingValue={4}
                                marginTop={'5rem'}
                                marginBottom={'5rem'}
                            />
                            <FeaturedGallery
                                images={
                                    homePageData?.section_featured_collection
                                }
                                hasVideo={false}
                                invert={false}
                                marginTop={5}
                                marginBottom={5}
                                title={
                                    homePageData?.featured_collection_title
                                        ?.collection_name
                                }
                                description={
                                    homePageData?.featured_collection_description
                                }
                                itemGroup={
                                    homePageData?.featured_collection_title
                                        ?.item_group_code
                                }
                                application={
                                    homePageData?.featured_collection_title
                                        ?.application_name
                                }
                                collection={
                                    homePageData?.featured_collection_title
                                        ?.collection_name
                                }
                            />

                            <Internationals
                                marginTop={15}
                                marginBottom={15}
                                data={homePageData?.section_internationals}
                                description={
                                    homePageData.internationals_description
                                }
                            />

                            {/* Janice Request to remove/hide for now; may revert */}
                            {/* <TradeCallToAction
                                image={tradeCallToActionImg}
                                header='Are you in the trade?'
                                // content="Join our Trade Professionals, and take advantage of exclusive"
                                // subContent="drops and discounts"
                            /> */}
                            <MailingListSignup
                                isColor={true}
                                marginBottom={5}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default HomePage;
