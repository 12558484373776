import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeStyles } from '../styles/Theme/ThemeStyles';
import { BrowserRouter as Routes } from 'react-router-dom';
import LoadingScreen from '../components/shared/loading/LoadingScreen';
import { ThemeProvider } from '@mui/material';

const queryClient = new QueryClient();

const AppProvider = ({ children }: any) => {
  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <ThemeProvider theme={ThemeStyles}>
          <QueryClientProvider client={queryClient}>
            <Routes>{children}</Routes>
          </QueryClientProvider>
        </ThemeProvider>
      </Suspense>
    </>
  );
};

export default AppProvider;
