import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import FilterComponent from '../../../components/shared/filter/FilterComponent';
import { useQuery } from 'react-query';
import { apiGetApplicationPageData } from '../../../api/apiApplications';
import LoadingScreen from '../../../components/shared/loading/LoadingScreen';
import FeaturedGallery from '../gallery/FeaturedGallery';
import TextHeader from '../../textHeader/TextHeader';
import NewArrivalsGrid from '../../../components/shared/grid/NewArrivalsGrid';
import MissingPage from '../errorMessages/MissingPage';
import CollectionsGrid from '../grid/CollectionsGrid';
import CarouselBanner from '../banner/CarouselBanner';
import { captureAnalytics } from '../../../utilities/Analytics';

const ApplicationTemplate = () => {
  const [applicationString, setApplicationString] = useState<string>();
  const { item_group, application } = useParams();
  const location = useLocation();

  const argumentDataStructure = {
    category: { application: application, item_group: item_group },
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: applicationData,
  } = useQuery<any, Error>([`application-${application}`], () =>
    apiGetApplicationPageData(argumentDataStructure)
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    // ! This needs to be fixed on the get-all-cms call on the backend, the frontend should not be handling this
    function capitalizeApplicationString(application: string | undefined) {
      let words = application?.split(' ');

      let capitalizedWords = words?.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });

      let capitalizedSentence = capitalizedWords?.join(' ');

      return capitalizedSentence;
    }

    let result = capitalizeApplicationString(application);
    setApplicationString(result);
  }, [application]);

  useEffect(() => {
    captureAnalytics(
      'pageview',
      location.pathname,
      `application-${application}`
    );
  }, [location.pathname, application]);

  return (
    <>
      {isError && <div>Something went wrong ...</div>}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {isError && <MissingPage />}
          {isSuccess && (
            <>
              <CarouselBanner
                images={applicationData?.section_hero}
                video={applicationData?.section_hero_video?.video_url}
              />

              <TextHeader
                heading={applicationData?.page_title}
                content={applicationData?.description}
                isCentered={false}
                width="70%"
                marginTop={10}
                marginBottom={5}
              />

              <FeaturedGallery
                images={applicationData?.section_featured_collection}
                hasVideo={false}
                invert={false}
                marginTop={5}
                marginBottom={5}
                title={
                  applicationData?.featured_collection_title?.collection_name
                }
                description={applicationData?.featured_collection_description}
                itemGroup={
                  applicationData?.featured_collection_title?.item_group_code
                }
                application={
                  applicationData?.featured_collection_title?.application_name
                }
                collection={
                  applicationData?.featured_collection_title?.collection_name
                }
              />

              <FilterComponent
                itemGroup={item_group}
                title={applicationString}
                initialApplications={[]}
              />
              <CollectionsGrid
                data={applicationData?.section_collections_in_application}
                applicationName={`${application}`}
                marginTop={5}
                marginBottom={5}
              />

              {/* <DesignBookCollection /> */}
              <NewArrivalsGrid
                header="New arrivals"
                data={applicationData?.section_new_arrivals}
                hasTitles={true}
                hasButtons={true}
                hasBackgroundText={false}
                spacingValue={4}
                marginTop={'5rem'}
                marginBottom={'5rem'}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ApplicationTemplate;
