export const fabricsData = [
  {
    id: '1',
    subtitle: 'curtaining',
    link: '../item_group/005/application/curtaining',
  },
  {
    id: '3',
    subtitle: 'dual purpose',
    link: '../item_group/005/application/dual purpose',
  },
  {
    id: '2',
    subtitle: 'upholstery',
    link: '../item_group/005/application/upholstery',
  },
];

export const wallpaperData = [
  {
    id: '1',
    subtitle: 'Wallpaper Calculator',
    link: '../wallpaper-calculator',
  },
];

export const companyData = [
  { id: '1', subtitle: 'about us', link: '../about-us' },
  { id: '3', subtitle: 'careers', link: '../careers' },
  { id: '2', subtitle: 'explore our spaces', link: '../store-locator' },
];

export const contactData = [
  {
    id: '1',
    subtitle: 'Phone',
    text: '+27 (0) 860 HERTEX (437839)',
    link: 'tel:0860437839',
  },
  {
    id: '2',
    subtitle: 'Email',
    text: 'info@hertex.co.za',
    link: 'mailto:info@hertex.co.za',
  },
  {
    id: '3',
    subtitle: 'Store Locator',
    text: 'Find a store near you',
    link: '../store-locator',
  },
];

export const homewareData = [
  { id: '1', subtitle: 'Shop Online', section: '1', link: '../item_group/016' },
];

export const brandsData = [
  {
    id: '1',
    subtitle: 'clarke & clarke',
    section: '1',
    link: '../brand/brand_name/Clarke & Clarke',
  },
  {
    id: '2',
    subtitle: 'editeur',
    link: '../brand/brand_name/Editeur',
    section: '1',
  },
  {
    id: '3',
    subtitle: 'harlequin',
    link: '../brand/brand_name/Harlequin',
    section: '1',
  },
  {
    id: '4',
    subtitle: 'sanderson',
    link: '../brand/brand_name/Sanderson',
    section: '1',
  },
  {
    id: '5',
    subtitle: 'masureel',
    link: '../brand/brand_name/Masureel',
    section: '1',
  },
  {
    id: '7',
    subtitle: 'hertex',
    link: '../brand/brand_name/Hertex',
    section: '1',
  },

  { id: '8', subtitle: 'haus', link: '../brand/brand_name/Haus', section: '1' },
  {
    id: '9',
    subtitle: 'hertex internationals',
    link: '../brand/brand_name/Hertex Internationals',
    section: '2',
  },
  {
    id: '10',
    subtitle: 'stonehaus',
    link: '../brand/brand_name/Stonehaus',
    section: '2',
  },
  {
    id: '11',
    subtitle: 'carefree',
    link: '../brand/brand_name/Carefree',
    section: '2',
  },
  {
    id: '12',
    subtitle: 'couture',
    link: '../brand/brand_name/Couture',
    section: '2',
  },
  {
    id: '13',
    subtitle: 'studioh',
    link: '../brand/brand_name/Studio H',
    section: '2',
  },
];
